/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-22",
    versionChannel: "nightly",
    buildDate: "2024-11-22T00:06:32.302Z",
    commitHash: "cec004add72855c0adc6db9aa86494a82d0d5ca0",
};
